<script lang="ts">
  import { isSmallScreen } from '$lib/helpers/window';
  import { _ } from '$lib/translations/i18n';
  import DesktopMenu from './DesktopMenu.svelte';
  import MobileMenu from './MobileMenu.svelte';
  import BalanseroLogo from '$lib/assets/img/balansero-logo.svg';

  export let onButtonClick: any;

  export let mainContent: HTMLElement | null = null;
  export let shouldHaveNavigation = true;

  let smallScreen = isSmallScreen();
</script>

<header
  id="header"
  class="header-main flex flex-row fixed inset-x-0 !bg-[#11343e] will-change-transform translate-y-0 z-10"
>
  {#if smallScreen}
    <MobileMenu {mainContent} {shouldHaveNavigation} />
  {:else}
    <div class="flex flex-row w-[100%] items-center justify-between py-5">
      <div class="ml-[5%] flex flex-row">
        <img src={BalanseroLogo} alt="balansero" height="45px" width="45px" />
        <!-- svelte-ignore a11y-invalid-attribute -->
        <a href={!shouldHaveNavigation ? './' : '#'} class="text-center">
          <h3 class="text-[1.75rem] text-white font-extrabold">BALANSERO</h3>
        </a>
      </div>

      <DesktopMenu {onButtonClick} {shouldHaveNavigation} />
    </div>
  {/if}
</header>

<style lang="scss">
  :global(.header-main) {
    transition: transform 400ms ease-out;
  }
  @keyframes fade-out-background {
    100% {
      background-color: transparent;
    }
  }
</style>
