<script lang="ts">
  import { translationKeys } from '$lib/translations/translationConstants';
  import { _ } from '$lib/translations/i18n';
  import servalitLogo from '$lib/assets/icons/icon-servalit-logo-white.svg';
  import { isSmallScreen } from '$lib/helpers/window';

  export let shouldHaveNavigation = true;
  let smallScreen = isSmallScreen();
</script>

<footer
  id="Footer"
  class="flex flex-col w-full md:w-[90%] items-center justify-center md:h-[100px] lg:h-[130px] md:mx-[5%]"
>
  {#if shouldHaveNavigation}
    <hr class="line w-full h-[0.0625rem] mb-[4.5rem]" />
  {/if}
  <div class="flex flex-col gap-y-2 md:flex-row w-full justify-between items-center">
    <div class="flex flex-col">
      <h3 class="text-white text-[1.75rem] font-extrabold">BALANSERO</h3>
      {#if !smallScreen}
        <span class="text-xs font-normal text-[#D7D7D7]"
          >Balansero @ {new Date().getFullYear()}. All rights reserved.</span
        >
      {/if}
    </div>
    {#if shouldHaveNavigation}
      <a id="Features" class="text-base font-medium text-white" href="#HowItWorks"
        >{$_(translationKeys.features)}</a
      >
      <!-- <a id="Pricing" class="text-base font-medium text-white" href="#PricingCards">
      {$_(translationKeys.pricing)}
    </a> -->
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a id="Contact" class="text-base font-medium text-white" href="#Discover">
        {$_(translationKeys.contact)}
      </a>
      <!-- svelte-ignore a11y-invalid-attribute -->
      <a id="Faq" class="text-base font-medium text-white" href="#FAQ">
        {$_(translationKeys.faq)}
      </a>
      <a id="Login" class="text-base font-medium text-white" href="/auth/login">
        {$_(translationKeys.logIn)}
      </a>
      <a class="text-base font-medium text-white" href="./privacyPolicy"
        >{$_(translationKeys.privacyPolicy)}</a
      >
    {/if}

    <img src={servalitLogo} alt={'Servalit logo'} class={'w-40 h-14'} />
    {#if smallScreen}
      <hr class="line w-full h-[0.0625rem] mb-[1.5rem] md:mb-[4.5rem]" />
      <span class="text-xs font-normal pb-2 lg:pb-0 text-[#D7D7D7]"
        >Balansero @ {new Date().getFullYear()}. All rights reserved.</span
      >
    {/if}
  </div>
</footer>

<style lang="scss">
  .line {
    background: rgba(255, 255, 255, 0.6);
  }
</style>
