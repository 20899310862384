<script lang="ts">
  export let onButtonClick: any;

  import { _, setupI18n } from '$lib/translations/i18n';
  import { translationKeys } from '$lib/translations/translationConstants';
  import {
    DEFAULT_LANGUAGE,
    sr_code,
    en_code,
    getLanguageFromLocale
  } from '$lib/helpers/languages';

  export let shouldHaveNavigation: boolean;

  let selected = getLanguageFromLocale();
</script>

<nav
  class="{!shouldHaveNavigation
    ? 'justify-end gap-4'
    : 'justify-between'} header-menu flex flex-row items-center text-lg font-medium text-white w-[55%] mr-[5%]"
>
  {#if shouldHaveNavigation}
    <a id="Features" class="body-l" href="#HowItWorks">{$_(translationKeys.features)}</a>
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a id="Faq" class="body-l" href="#FAQ">
      {$_(translationKeys.faq)}
    </a>
    <!-- svelte-ignore a11y-invalid-attribute -->
    <a id="Contact" class="body-l" href="#Discover">
      {$_(translationKeys.contact)}
    </a>
  {/if}
  <select
    class="language-select body-l cursor-pointer"
    bind:value={selected}
    on:change={() => setupI18n({ withLocale: selected ?? DEFAULT_LANGUAGE })}
  >
    <option class="language-option" value={en_code} selected>ENG</option>
    <option class="language-option" value={sr_code}>SRB</option>
  </select>
  <div class="flex items-center justify-center">
    <button
      on:click={onButtonClick}
      class="orange-button flex items-center justify-center w-[6.4rem] h-[3rem] rounded-lg"
    >
      <span class="text-white text-base font-bold tracking-[0.015em]"
        >{$_(translationKeys.logIn)}</span
      >
    </button>
  </div>
</nav>

<style lang="scss">
  .header-menu {
    @media (max-width: 1200px) {
      width: 65%;
    }
  }
  .language-select {
    background-color: transparent;
    border: none;
    color: white;
  }
  .language-select option {
    background: #12353f;
    font-size: 1.35rem;
    color: white;
    cursor: pointer;
  }
</style>
