<script lang="ts">
  export let mainContent: HTMLElement | null;

  import { _, setupI18n } from '$lib/translations/i18n';
  import { translationKeys } from '$lib/translations/translationConstants';

  import TinyGesture from 'tinygesture';
  import { onDestroy } from 'svelte';
  import Drawer from 'svelte-drawer-component';
  import { Button } from 'flowbite-svelte';
  import { BarsSolid, XmarkSolid } from 'svelte-awesome-icons';
  import {
    DEFAULT_LANGUAGE,
    sr_code,
    en_code,
    getLanguageFromLocale
  } from '$lib/helpers/languages';
  import BalanseroLogo from '$lib/assets/img/balansero-logo.svg';

  export let shouldHaveNavigation: boolean;

  let drawer: HTMLElement;
  let drawerOpen = false;
  let mainContentGesture: TinyGesture;
  let featuresLink: HTMLElement;
  let drawerGesture: TinyGesture;
  let selected = getLanguageFromLocale();

  $: mainContent, setDrawerGesture();

  function setDrawerGesture() {
    if (mainContent) {
      mainContentGesture = new TinyGesture(mainContent, {
        mouseSupport: false
      });
      mainContentGesture.on('swipeleft', () => {
        drawerOpen = true;
      });
    }
    if (drawer) {
      drawerGesture = new TinyGesture(drawer, {
        mouseSupport: false
      });
      drawerGesture.on('swiperight', () => {
        drawerOpen = false;
      });
      featuresLink.focus();
    }
  }

  onDestroy(() => {
    if (mainContentGesture) {
      mainContentGesture.destroy();
    }
    if (drawerGesture) {
      drawerGesture.destroy();
    }
  });
</script>

<div class="menu-container">
  <div class="flex flex-row justify-between w-full pl-5 min-h-[7vh] items-center">
    <a class="main-title flex flex-row items-center" href={!shouldHaveNavigation ? './' : '#'}>
      <img src={BalanseroLogo} alt="balansero" height="35px" width="35px" />
      BALANSERO
    </a>
    {#if !drawerOpen}
      <Button on:click={() => (drawerOpen = !drawerOpen)}><BarsSolid /></Button>
    {/if}
  </div>
  <Drawer open={drawerOpen} size="60%" placement="right" on:clickAway={() => (drawerOpen = false)}>
    <div class="drawer-container" bind:this={drawer}>
      <Button on:click={() => (drawerOpen = false)}><XmarkSolid /></Button>
      <nav class="header-menu">
        {#if !shouldHaveNavigation}
          <a id="Features" class="feature body-l" href="./#HowItWorks" bind:this={featuresLink}
            >{$_(translationKeys.features)}</a
          >
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a id="Faq" class="feature body-l" href="./#FAQ">
            {$_(translationKeys.faq)}
          </a>
          <!-- svelte-ignore a11y-invalid-attribute -->
          <a id="Contact" class="feature body-l" href="./#Discover">
            {$_(translationKeys.contact)}
          </a>
        {/if}
        <a id="Login" class="feature body-l" href="/auth/login">
          {$_(translationKeys.logIn)}
        </a>
        <select
          class="language-select body-l"
          bind:value={selected}
          on:change={() => setupI18n({ withLocale: selected ?? DEFAULT_LANGUAGE })}
        >
          <option class="language-option" value={en_code} selected>ENG</option>
          <option class="language-option" value={sr_code}>SRB</option>
        </select>
      </nav>
    </div>
  </Drawer>
</div>

<style lang="scss">
  :global(.menu-icon) {
    color: white;
  }
  .menu-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    min-height: 5vh;
  }
  :global(.close-icon) {
    margin-left: 75%;
  }
  .menu-container :global(.drawer .overlay) {
    display: none;
  }
  .menu-container :global(.drawer .panel) {
    position: absolute;
    z-index: 10;
    min-height: 100vh;
    background-color: #21272a;
    color: white;
  }
  .drawer-container {
    height: 100%;
  }
  .header-menu {
    color: white;
    font-size: 1.4rem;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 15%;
    padding-left: 15%;
  }
  .language-select {
    background-color: transparent;
    border: none;
    color: white;
  }
  .language-select option {
    background-color: #21272a;
    font-size: 1rem;
    color: white;
    border: none;
  }
  .header-menu .feature {
    color: white;
    margin-bottom: 10%;
  }
</style>
